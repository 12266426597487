import { BankDetailsSelectorOption } from 'shared/types';

export const TABLE_CUSTOMER_PRICES = 'CustomerPrices' as const;
export const VIEW_CUSTOMER_PRICES = 'vwCustomerPrices' as const;
export const VIEW_CUSTOMER_PRICES_EXCEPTIONS = 'vwCustomerPricesExceptions' as const;
export const TABLE_CUSTOMER_PRICES_EXCEPTIONS = 'CustomerPricesExceptions' as const;
export const TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES = 'CustomerPricesPersistedAttributes' as const;
export const VIEW_MATERIAL_KAI_KG_CONVERSION = 'vwMaterialKAIKGConversion' as const;
export const TABLE_UOM_CONVERSION = 'UOMConversion' as const;
export const TABLE_CUSTOMER_PRICES_USER_PERMITTED_ROW_LEVELS = 'CustomerPricesUserPermittedRowLevels' as const;
export const TABLE_CUSTOMER_PRICES_COMMENTS = 'CustomerPricesComments' as const;
export const VIEW_CUSTOMER_PRICE_HISTORY = 'vwCustomerPriceHistory' as const;
export const VIEW_CUSTOMER_PRODUCT_ENGINE_BATCH_META_DATA = 'vwCustomerProductEngineBatchMetaData' as const;
export const TABLE_QUOTE_DEFAULTS = 'QuoteDefaults' as const;
export const VIEW_STANDARD_NON_STANDARD_UOMS = 'vwDropdownNonStandardAndStandardUoms' as const;
export const SP_GET_CUSTOMER_PRICE_HISTORY = 'spGetCustomerPriceHistory' as const;
export const QUERY_NAME_CUSTOMER_PRICES_SUMMARY = 'spCustomerPricesSummary' as const;
export const CUSTOMER_PRICES_PDF_COMPANY_NAME_OPTIONS = 'vwCompanies' as const;

export const customerPriceStatuses = {
    APPROVED: 'Approved',
    REJECTED: 'Not Approved',
    APPROVAL_REQUIRED: 'Approval Required',
    NEEDS_REVIEW: 'Needs Review',
    NO_CHANGE: 'No Change',
};

export const CUSTOMER_PRICES_PDF_COMPANY_OPTIONS_DEMO = [
    'Insight2Profit Cleveland',
    'Insight2Profit Chicago',
    'Insight2Profit',
];

export const CUSTOMER_PRICES_PDF_ADDRESS_OPTIONS_DEMO = [
    `Insight2Profit
125 S Wacker Dr SUITE 1300, 
Chicago, IL 60606
United States`,
    `Insight2Profit
3333 Richmond Rd SUITE200,
Beachwood, OH 44122
United States`,
];

export const CUSTOMER_PRICES_PDF_BANK_SELECTION_OPTIONS: BankDetailsSelectorOption[] = [
    {
        currency: 'USD',
        branchName: 'HSBC BANK (CHINA) COMPANY LIMITED',
        accountHolder: 'Nouryon Chemicals MCA (Taixing) Co. Ltd.',
        accountNumber: '1039916055',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'JPY',
        branchName: 'HSBC BANK (CHINA) COMPANY LIMITED',
        accountHolder: 'Nouryon Chemicals MCA (Taixing) Co. Ltd.',
        accountNumber: '1039916056',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'CNY',
        branchName: 'HSBC BANK (CHINA) COMPANY LIMITED',
        accountHolder: 'Nouryon Chemicals MCA (Taixing) Co. Ltd.',
        accountNumber: '88013271011',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'USD',
        branchName: 'ICBC, Taixing Kaifaqu Br.',
        accountHolder: 'Nouryon Chemicals MCA (Taixing) Co. Ltd.',
        accountNumber: '1115926019300240000',
        swiftCode: 'ICBKCNBJ',
    },
    {
        currency: 'EUR',
        branchName: 'ICBC, Taixing Kaifaqu Br.',
        accountHolder: 'Nouryon Chemicals MCA (Taixing) Co. Ltd.',
        accountNumber: '1115926019300240000',
        swiftCode: 'ICBKCNBJ',
    },
    {
        currency: 'JPY',
        branchName: 'ICBC, Taixing Kaifaqu Br.',
        accountHolder: 'Nouryon Chemicals MCA (Taixing) Co. Ltd.',
        accountNumber: '1115926019327000000',
        swiftCode: 'ICBKCNBJ',
    },
    {
        currency: 'CNY',
        branchName: 'HSBC BANK (CHINA) COMPANY LIMITED',
        accountHolder: 'Nouryon Chemicals (Guangzhou) Co.,Ltd',
        accountNumber: '629096611011',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'USD',
        branchName: 'ICBC, Guangzhou Kaifaqu Br.',
        accountHolder: 'Nouryon Chemicals (Guangzhou) Co.,Ltd',
        accountNumber: '3602005729200260000',
        swiftCode: 'ICBKCNBJ',
    },
    {
        currency: 'JPY',
        branchName: 'Ningbo',
        accountHolder: 'Nouryon Chemicals (Ningbo) Co. Ltd.',
        accountNumber: '20002101057',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'EUR',
        branchName: 'Ningbo',
        accountHolder: 'Nouryon Chemicals (Ningbo) Co. Ltd.',
        accountNumber: '20002101056',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'USD',
        branchName: 'Ningbo',
        accountHolder: 'Nouryon Chemicals (Ningbo) Co. Ltd.',
        accountNumber: '20002101055',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'CNY',
        branchName: 'HSBC BANK (CHINA) COMPANY LIMITED',
        accountHolder: 'Nouryon Chemicals (Ningbo) Co. Ltd.',
        accountNumber: '88238308011',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'USD',
        branchName: 'ICBC, Ningbo Zhenhai Branch',
        accountHolder: 'Nouryon Chemicals (Ningbo) Co. Ltd.',
        accountNumber: '3901160009814020000',
        swiftCode: 'ICBKCNBJ',
    },
    {
        currency: 'JPY',
        branchName: 'ICBC, Ningbo Zhenhai Branch',
        accountHolder: 'Nouryon Chemicals (Ningbo) Co. Ltd.',
        accountNumber: '3901160009827000000',
        swiftCode: 'ICBKCNBJ',
    },
    {
        currency: 'EUR',
        branchName: 'ICBC, Ningbo Zhenhai Branch',
        accountHolder: 'Nouryon Chemicals (Ningbo) Co. Ltd.',
        accountNumber: '3901160009838000000',
        swiftCode: 'ICBKCNBJ',
    },
    {
        currency: 'CNY',
        branchName: 'HSBC BANK (CHINA) COMPANY LIMITED',
        accountHolder: 'Nouryon Chemicals (BoXing) Co., Ltd.',
        accountNumber: '163009939011',
        swiftCode: 'HSBCCNSH',
    },
    {
        currency: 'USD',
        branchName: 'HSBC, Jinan Branch',
        accountHolder: 'Nouryon Chemicals (BoXing) Co., Ltd.',
        accountNumber: '23009939055',
        swiftCode: 'HSBCCNSHJIN',
    },
    {
        currency: 'EUR',
        branchName: 'ICBC, boxing Br.',
        accountHolder: 'Nouryon Chemicals (BoXing) Co., Ltd.',
        accountNumber: '1613002309200200000',
        swiftCode: 'ICBKCNBJ',
    },
    {
        currency: 'INR',
        branchName: 'DEUTSCHE BANK AG, DELHI BRANCH',
        accountHolder: 'Nouryon Chemicals India Private Limited',
        accountNumber: '1563386000',
        swiftCode: 'DEUTINBBDEL',
    },
    {
        currency: 'INR',
        branchName: 'DEUTSCHE BANK AG, DELHI BRANCH',
        accountHolder: 'Nouryon Chemicals India Private Limited',
        accountNumber: '1563386001',
        swiftCode: 'DEUTINBBDEL',
    },
    {
        currency: 'IDR',
        branchName: 'HSBC BANK LIMITED (INDONESIA)',
        accountHolder: 'Nouryon Surface Chemistry Pte. Ltd.',
        accountNumber: '50206473068',
        swiftCode: 'HSBCIDJA',
    },
    {
        currency: 'JPY',
        branchName: 'HSBC BANK LIMITED (JAPAN)',
        accountHolder: 'Nouryon K.K.',
        accountNumber: '9048794001',
        swiftCode: 'HSBCJPJT',
    },
    {
        currency: 'EUR',
        branchName: 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED, SINGA',
        accountHolder: 'Nouryon Surface Chemistry Pte. Ltd.',
        accountNumber: '260270491179',
        swiftCode: 'HSBCSGSG',
    },
    {
        currency: 'SGD',
        branchName: 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED, SINGA',
        accountHolder: 'Nouryon Surface Chemistry Pte. Ltd.',
        accountNumber: '141504001001',
        swiftCode: 'HSBCSGSG',
    },
    {
        currency: 'USD',
        branchName: 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED, SINGA',
        accountHolder: 'Nouryon Surface Chemistry Pte. Ltd.',
        accountNumber: '260270491178',
        swiftCode: 'HSBCSGSG',
    },
    {
        currency: 'TWD',
        branchName: 'HSBC BANK (TAIWAN) LIMITED',
        accountHolder: 'Nouryon Asia Pte. Ltd. - Taiwan Branch',
        accountNumber: '3076007031',
        swiftCode: 'HSBCTWTP',
    },
    {
        currency: 'EUR',
        branchName: 'HSBC BANK (TAIWAN) LIMITED',
        accountHolder: 'Nouryon Asia Pte. Ltd. - Taiwan Branch',
        accountNumber: '3076007063',
        swiftCode: 'HSBCTWTP',
    },
    {
        currency: 'JPY',
        branchName: 'HSBC BANK (TAIWAN) LIMITED',
        accountHolder: 'Nouryon Asia Pte. Ltd. - Taiwan Branch',
        accountNumber: '3076007062',
        swiftCode: 'HSBCTWTP',
    },
    {
        currency: 'USD',
        branchName: 'HSBC BANK (TAIWAN) LIMITED',
        accountHolder: 'Nouryon Asia Pte. Ltd. - Taiwan Branch',
        accountNumber: '3076007061',
        swiftCode: 'HSBCTWTP',
    },
    {
        currency: 'EUR',
        branchName: 'CAIXABANK, S.A.',
        accountHolder: 'Nouryon Chemicals SA',
        accountNumber: 'ES8921000130140200445102',
        swiftCode: 'CAIXESBB',
    },
    {
        currency: 'EUR',
        branchName: 'CAIXABANK, S.A.',
        accountHolder: 'Nouryon Chemicals SA',
        accountNumber: 'ES24210000130140200269926',
        swiftCode: 'CAIXESBB',
    },
    {
        currency: 'USD',
        branchName: 'ING BELGIUM NV/SA (FORMERLY BANK BRUSSELS LAMBERT SA), BRUSS',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'BE20375104854756',
        swiftCode: 'BBRUBEBB',
    },
    {
        currency: 'DKK',
        branchName: 'SKANDINAVISKA ENSKILDA BANKEN AB (DK)',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'DK2552950010040558',
        swiftCode: 'ESSEDKKK',
    },
    {
        currency: 'EUR',
        branchName: 'ING BANK FRANCE COMMERCIAL BANKING',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'FR7630438000013828603600486',
        swiftCode: 'INGBFRPP',
    },
    {
        currency: 'EUR',
        branchName: 'ING-DIBA AG (COMMERCIAL BANKING)',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'DE52500210000010123776',
        swiftCode: 'INGBDEFF',
    },
    {
        currency: 'EUR',
        branchName: 'ING BANK N.V.',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'NL50INGB0657892696',
        swiftCode: 'INGBNL2A',
    },
    {
        currency: 'PLN',
        branchName: 'ING BANK SLASKI SA',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'PL30105000861000009030126297',
        swiftCode: 'INGBPLPW',
    },
    {
        currency: 'EUR',
        branchName: 'CAIXABANK, S.A.',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'ES4921000130150700001787',
        swiftCode: 'CAIXESBB',
    },
    {
        currency: 'EUR',
        branchName: 'SKANDINAVISKA ENSKILDA BANKEN AB (SE)',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'SE3250000000057468249733',
        swiftCode: 'ESSESESS',
    },
    {
        currency: 'GBP',
        branchName: 'ING BANK N.V., LONDON BRANCH',
        accountHolder: 'Nouryon Functional Chemicals B.V.',
        accountNumber: 'GB68INGB23885920392238',
        swiftCode: 'INGBGB22',
    },
    {
        currency: 'USD',
        branchName: 'HSBC BANK MIDDLE EAST LIMITED',
        accountHolder: 'Nouryon Middle East FZE',
        accountNumber: 'AE760200000035439777100',
        swiftCode: 'BBMEAEAD',
    },
    {
        currency: 'EUR',
        branchName: 'HSBC BANK MIDDLE EAST LIMITED',
        accountHolder: 'Nouryon Middle East FZE',
        accountNumber: 'AE490200000035439777101',
        swiftCode: 'BBMEAEAD',
    },
    {
        currency: 'AED',
        branchName: 'HSBC BANK MIDDLE EAST LIMITED',
        accountHolder: 'Nouryon Middle East FZE',
        accountNumber: 'AE330200000035439777001',
        swiftCode: 'BBMEAEAD',
    },
    {
        currency: 'EUR',
        branchName: 'SKANDINAVISKA ENSKILDA BANKEN AB (FI)',
        accountHolder: 'Nouryon Pulp and Performance Chemicals AB',
        accountNumber: 'FI1133010001239714',
        swiftCode: 'ESSEFIHX',
    },
    {
        currency: 'NOK',
        branchName: 'SKANDINAVISKA ENSKILDA BANKEN AB (NO)',
        accountHolder: 'Nouryon Pulp and Performance Chemicals AB',
        accountNumber: 'NO1997500718645',
        swiftCode: 'ESSENOKX',
    },
    {
        currency: 'EUR',
        branchName: 'CAIXABANK, S.A.',
        accountHolder: 'Nouryon Pulp and Performance Chemicals AB',
        accountNumber: 'ES1521000130100700001674 ',
        swiftCode: 'CAIXESBB',
    },
    {
        currency: 'SEK',
        branchName: 'SKANDINAVISKA ENSKILDA BANKEN AB (SE)',
        accountHolder: 'Nouryon Pulp and Performance Chemicals AB',
        accountNumber: 'SE4250000000055651032444',
        swiftCode: 'ESSESESS',
    },
    {
        currency: 'EUR',
        branchName: 'CAIXABANK, S.A.',
        accountHolder: 'Nouryon Surface Chemistry AB',
        accountNumber: 'ES8321008740580700001285',
        swiftCode: 'CAIXESBB',
    },
    {
        currency: 'SEK',
        branchName: 'SKANDINAVISKA ENSKILDA BANKEN AB (SE)',
        accountHolder: 'Nouryon Surface Chemistry AB',
        accountNumber: 'SE0950000000055441002042',
        swiftCode: 'ESSESESS',
    },
    {
        currency: 'SEK',
        branchName: 'SKANDINAVISKA ENSKILDA BANKEN AB (SE)',
        accountHolder: 'Nouryon Functional Chemicals AB',
        accountNumber: 'SE2150000000055441003669',
        swiftCode: 'ESSESESS',
    },
    {
        currency: 'SEK',
        branchName: 'SKANDINAVISKA ENSKILDA BANKEN AB (SE)',
        accountHolder: 'Nouryon Functional Chemicals AB',
        accountNumber: 'SE9850000000055651012230',
        swiftCode: 'ESSESESS',
    },
    {
        currency: 'BRL',
        branchName: 'BANCO BRADESCO S.A.',
        accountHolder: 'Nouryon Pulp and Performance Indústria Química Ltda',
        accountNumber: '33002-7',
        swiftCode: 'BBDEBRSP',
    },
    {
        currency: 'BRL',
        branchName: 'BANCO SANTANDER (BRASIL) S.A.',
        accountHolder: 'Nouryon Pulp and Performance Indústria Química Ltda',
        accountNumber: '130036700',
        swiftCode: 'BSCHBRSP',
    },
    {
        currency: 'BRL',
        branchName: 'ITAU UNIBANCO S/A',
        accountHolder: 'Nouryon Pulp and Performance Indústria Química Ltda',
        accountNumber: '70650-6',
        swiftCode: 'ITAUBRSP',
    },
    {
        currency: 'CAD',
        branchName: 'HSBC Bank Canada',
        accountHolder: 'Nouryon Chemicals Ltd',
        accountNumber: '002-898314-001',
        swiftCode: 'HKBCCATT',
    },
    {
        currency: 'CAD',
        branchName: 'HSBC Bank Canada',
        accountHolder: 'Nouryon Pulp and Performance Canada Inc.',
        accountNumber: '002-898349-002',
        swiftCode: 'HKBCCATT "',
    },
    {
        currency: 'USD',
        branchName: 'HSBC Bank Canada',
        accountHolder: 'Nouryon Chemicals Ltd',
        accountNumber: '002-898314-070',
        swiftCode: 'HKBCCATT',
    },
    {
        currency: 'USD',
        branchName: 'HSBC Bank Canada',
        accountHolder: 'Nouryon Pulp and Performance Canada Inc.',
        accountNumber: '002-898349-071',
        swiftCode: 'HKBCCATT',
    },
    {
        currency: 'MXN',
        branchName: 'HSBC MEXICO, S.A. INSTITUCION DE BANCA MULTIPLE, GR',
        accountHolder: 'Nouryon Chemicals, S.A. de C.V.',
        accountNumber: '4057611733',
        swiftCode: 'BIMEMXMM',
    },
    {
        currency: 'USD',
        branchName: 'HSBC MEXICO, S.A. INSTITUCION DE BANCA MULTIPLE, GR',
        accountHolder: 'Nouryon Chemicals, S.A. de C.V.',
        accountNumber: '7003694890',
        swiftCode: 'BIMEMXMM',
    },
    {
        currency: 'USD',
        branchName: 'BANK OF AMERICA, N.A.',
        accountHolder: 'Nouryon Chemicals LLC',
        accountNumber: '4427105241',
        swiftCode: 'BOFAUS3N',
    },
    {
        currency: 'USD',
        branchName: 'BANK OF AMERICA, N.A.',
        accountHolder: 'Nouryon Chemicals LLC',
        accountNumber: '4427105254',
        swiftCode: 'BOFAUS3N',
    },
    {
        currency: 'EUR',
        branchName: 'NORDEA BANK FINLAND',
        accountHolder: 'Nouryon Chemicals Finland Oy',
        accountNumber: 'FI46 2333 1800 0191 11',
        swiftCode: 'NDEAFIHH',
    },
    {
        currency: 'USD',
        branchName: 'NORDEA BANK FINLAND',
        accountHolder: 'Nouryon Chemicals Finland Oy',
        accountNumber: 'FI89 1207 5200 0006 26',
        swiftCode: 'NDEAFIHH',
    },
    {
        currency: 'EUR',
        branchName: 'SEB Finland',
        accountHolder: 'Nouryon Chemicals Finland Oy',
        accountNumber: 'FI1033010001166594',
        swiftCode: 'ESSEFIHX',
    },
];
export const CUSTOMER_PRICES_PDF_BANK_SELECTION_OPTIONS_DEMO: BankDetailsSelectorOption[] = [
    {
        currency: 'USD',
        branchName: 'United States Bank',
        accountHolder: 'Insight2Profit Chemicals US Co. Ltd.',
        accountNumber: '0123456789',
        swiftCode: 'USBI2P',
    },
    {
        currency: 'EUR',
        branchName: 'Bank of Europe',
        accountHolder: 'Insight2Profit Chemicals Europe Co. Ltd.',
        accountNumber: '9876543210',
        swiftCode: 'ICBKCNBJ',
    },
];

export const PAYMENT_TERMS = [
    'CAD - Cash against documents (D/P)',
    'I005 - Within 5 days after date of invoice',
    'I010 - Within 10 days after date of invoice',
    'I015 - Within 15 days after date of invoice',
    'I020 - Within 20 days after date of invoice',
    'I025 - Within 25 days after date of invoice',
    'I030 - Within 30 days after date of invoice',
    'I040 - Within 40 days after date of invoice',
    'I045 - Within 45 days after date of invoice',
    'I055 - Within 55 days after date of invoice',
    'I060 - Within 60 days after date of invoice',
    'I075 - Within 75 days after date of invoice',
    'I090 - Within 90 days after date of invoice',
    'L000 - L/C payable at sight',
    'L030 - L/C payable 30 days after date of shipment',
    'L045 - L/C payable 45 days after date of shipment',
    'L060 - L/C payable 60 days after date of shipment',
    'M030 - Within 30 days after end of month of invoice',
    'M040 - Within 40 days after end of month of invoice',
    'M060 - Within 60 days after end of month of invoice',
    'X001 - Payment in advance',
    'X000 - Without payment',
    'BL30 - 30 days after Bill of Lading',
    'BL45 - 45 days after Bill of Lading',
    'BL60 - 60 days after Bill of Lading',
    'BL90 - 90 days after Bill of Lading',
    'D030 - Against Draft 30 days after date of shipment',
    'D045 - Against Draft 45 days after date of shipment',
    'D060 - Against Draft 60 days after date of shipment',
    'J030 - RIBA 30 days after end of month of invoice',
    'J040 - RIBA 45 days after end of month of invoice',
];

export const USER_ADDED_SAP_DATASOURCE = 'User added SAP customer';
export const USER_ADDED_PROSPECTIVE_DATA_SOURCE = 'User added prospective customer';
