import { useService } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ITableCustomerPricesComments } from 'shared/types';

interface useCustomerPriceCommentsMutationProps {
    onSuccess: () => void;
}

export function useCustomerPriceCommentsMutation({ onSuccess }: useCustomerPriceCommentsMutationProps) {
    const { customerPricesCommentsService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<DataAccessResponse<ITableCustomerPricesComments>, Error, ITableCustomerPricesComments>(
        {
            mutationKey: ['addCustomerPriceComment'],
            mutationFn: async newRow => {
                return await customerPricesCommentsService.addComment(newRow);
            },
            onSuccess: () => {
                enqueueSnackbar('Comment submitted', { variant: 'success' });
                onSuccess();
            },
            onError: error => {
                enqueueSnackbar('There was an error submitting the comment', { variant: 'error' });
            },
        }
    );
    return mutation;
}
