import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField, SaveButton } from 'shared/components/forms';
import { customerPriceStatuses } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { usePricesInvalidator } from 'shared/providers';
import {
    useCustomerPriceCommentsMutation,
    useCustomerPricesExceptionsApproveMutation,
    useCustomerPricesExceptionsRejectMutation,
} from 'shared/mutations';
import {
    ITableCustomerPricesComments,
    ITableCustomerPricesCommentsForm,
    IViewCustomerPricesExceptions,
} from 'shared/types';
import { TooltipIconButton } from '../../productPrices';
import { useService, useUser } from '@insight2profit/drive-app';

type ProductPricesApprovalActionsProps = {
    params: GridRowParams<IViewCustomerPricesExceptions>;
    isInEditMode?: boolean;
};

const APPROVE = 'Approve';
const REJECT = 'Reject';

type DialogType = typeof APPROVE | typeof REJECT | null;

export function CustomerPricesExceptionsApprovalActions({ params, isInEditMode }: ProductPricesApprovalActionsProps) {
    const [dialogType, setDialogType] = useState<DialogType>(null);
    const { userApproverTier } = useUserPermissions();
    const { customerPriceApprovalService } = useService();
    const { invalidateQuery } = usePricesInvalidator();
    const user = useUser();

    const {
        handleSubmit,
        control,
        formState: { errors },
        resetField,
        reset,
    } = useForm<ITableCustomerPricesCommentsForm>({
        defaultValues: {
            comment: '',
        },
    });

    const commentMutation = useCustomerPriceCommentsMutation({ onSuccess: () => {} });
    const rejectMutation = useCustomerPricesExceptionsRejectMutation();
    const approveMutation = useCustomerPricesExceptionsApproveMutation();

    const openApproveDialog = () => {
        setDialogType(APPROVE);
    };

    const openRejectDialog = () => {
        setDialogType(REJECT);
    };

    const closeDialog = () => {
        reset();
        setDialogType(null);
    };

    const isOpen = !!dialogType;

    const onSubmit = (form: ITableCustomerPricesCommentsForm) => {
        if (!form.comment) throw Error('Comment cannot be blank');
        const today = new Date();
        const formattedToday = today.toISOString();
        if (params.row && user) {
            const newCommentPayload: ITableCustomerPricesComments = {
                id: 0,
                businessLine: params.row.businessLine || '',
                materialId: params.row.materialId || '',
                orgRegion: params.row.orgRegion || '',
                application: params.row.application || '',
                shipToId: params.row.shipToId || '',
                soldToId: params.row.soldToId || '',
                comment: form.comment,
                modifiedBy: user.displayName,
                effectiveStart: formattedToday,
                effectiveEnd: null,
                deleted: false,
            };

            if (dialogType === APPROVE) {
                approveMutation.mutate(params.row);
            }

            if (dialogType === REJECT) {
                rejectMutation.mutate(params.row);
            }

            commentMutation.mutate(newCommentPayload);
            resetField('comment');
            invalidateQuery();
            closeDialog();
        }
    };

    const canApprove = customerPriceApprovalService.canCurrentUserApprove({
        row: params.row,
        userApproverTier,
    });

    const requiresApproval = params.row.status === customerPriceStatuses.APPROVAL_REQUIRED;

    const isDisabled = !requiresApproval || !canApprove || !!errors?.['comment'];
    const isLoading = rejectMutation.isLoading || approveMutation.isLoading || commentMutation.isLoading;

    return !isInEditMode
        ? [
              <TooltipIconButton
                  key={`approve-tooltip-btn-${params.row.id}`}
                  tooltip='Approve'
                  onClick={openApproveDialog}
                  Icon={DoneIcon}
                  color='green'
                  isDisabled={isDisabled}
              />,
              <TooltipIconButton
                  key={`reject-tooltip-btn-${params.row.id}`}
                  tooltip='Reject'
                  onClick={openRejectDialog}
                  Icon={CloseIcon}
                  color='red'
                  isDisabled={isDisabled}
              />,
              <Dialog
                  key={`approval-dialog-${params.row.id}`}
                  onClose={closeDialog}
                  open={isOpen}
                  maxWidth={'sm'}
                  fullWidth
              >
                  <DialogTitle>{dialogType}</DialogTitle>
                  <DialogContent>
                      <Box sx={{ p: 2 }}>
                          <FormTextField
                              name={'comment'}
                              label={'Comment'}
                              control={control}
                              rules={{ maxLength: 1000, required: 'cannot be blank' }}
                              fieldError={errors['comment']}
                          />
                      </Box>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={closeDialog}>Cancel</Button>
                      <SaveButton
                          text={dialogType || ''}
                          submitHandler={handleSubmit(onSubmit)}
                          isLoading={isLoading}
                          isDisabled={!!errors['comment']}
                      />
                  </DialogActions>
              </Dialog>,
          ]
        : [];
}
