import { CLIENT_ID, nextAuthority } from '@insight2profit/drive-app';
import {
    AuthorizationService,
    AxiosInstance,
    DataAccessService,
    EventService,
    ExportService,
    PowerBiService,
} from '@price-for-profit/micro-services';
import {
    DATABASE_LABEL,
    TABLE_PRODUCT_PRICES_PERSISTED_ATTRIBUTES,
    TABLE_UOM_CONVERSION,
    VIEW_MATERIAL_NON_STANDARD_UOM_CONVERSION,
    VIEW_STANDARD_NON_STANDARD_UOMS,
} from 'shared/constants';
import {
    BatchMetaDataService,
    BatchService,
    CustomerPriceApprovalService,
    CustomerPricesCommentsService,
    CustomerPricesExceptionsService,
    CustomerPricesPersistedAttributesService,
    CustomerPricesQuoteService,
    CustomerPricesService,
    CustomerPricesSummaryService,
    ExchangeRatesService,
    ProductPriceApprovalService,
    ProductPricesCommentsService,
    ProductPricesLocksService,
    ProductPricesPersistedAttributesService,
    ProductPricesService,
    ProductPricesSummaryService,
    RowLevelSecurityDasService,
    UomConversionService,
} from 'shared/services';
import { CustomerPricesAddExistingSAPService } from 'shared/services/customerPriceAddExistingSAPService';
import { CustomerPricesAddProspectiveService } from 'shared/services/customerPriceAddProspectiveService';
import { DropDownService } from 'shared/services/generic/dropdownService';
import { GridService } from 'shared/services/generic/gridService';
import { NamedQueryService } from 'shared/services/generic/namedQueryService';
import { TableCrudService } from 'shared/services/generic/tableCrudService';
import { ProductPricesAddNewService } from 'shared/services/productPricesAddNewService';
import { ProductPricesMarketPriceHistoryService } from 'shared/services/productPricesMarketPriceHistoryService';

import {
    AppConfig,
    ITableCustomerPrices,
    ITableNonStandardUOMConversion,
    ITableProductPrices,
    ITableProductPricesPersistedAttributes,
    ITableUOMConversion,
    IViewDropdownNonStandardAndStandardUoms,
    IViewQuoteMostRecentApproved,
    IVwDropDownCustomerPricesShipTo,
    IVwDropDownCustomerPricesSoldTo,
    IVwDropdownCompanyAddresses,
    IVwDropdownCompanyNames,
    IVwDropdownCustomerPricesAccountManagers,
    IVwDropdownCustomerPricesAddExistingSalesOrganizations,
    IVwDropdownCustomerPricesAddProspectiveSalesOrganizations,
    IVwDropdownCustomerPricesApplications,
    IVwDropdownCustomerPricesMarketSegments,
    IVwDropdownCustomerPricesMaterials,
    IVwDropdownCustomerPricesOrgRegions,
    IVwDropdownProductPricesApplications,
    IVwDropdownProductPricesMarketSegments,
    IVwDropdownProductPricesMaterials,
    IVwDropdownProductPricesOrgRegions,
} from 'shared/types';

export function createServiceContext(
    appConfig: AppConfig,
    httpClient: AxiosInstance,
    authClientName: string
): app.ServiceContext<AppConfig> {
    const eventService = new EventService(httpClient);
    const authService = new AuthorizationService(httpClient);
    const powerBiService = new PowerBiService(httpClient);
    const exportService = new ExportService(httpClient);

    const dasService = new DataAccessService(httpClient);
    const rlsDasService = new RowLevelSecurityDasService(httpClient);

    const exchangeRatesService = new ExchangeRatesService(dasService);
    const productPriceApprovalService = new ProductPriceApprovalService();
    const productPricesLocksService = new ProductPricesLocksService(dasService);
    const productPricesService = new ProductPricesService(
        rlsDasService,
        productPriceApprovalService,
        productPricesLocksService,
        exchangeRatesService
    );
    const productPricesPersistedAttributesTableCrudService = new TableCrudService<ITableProductPricesPersistedAttributes>(
        dasService,
        TABLE_PRODUCT_PRICES_PERSISTED_ATTRIBUTES
    );
    const productPricesPersistedAttributesService = new ProductPricesPersistedAttributesService(
        productPricesPersistedAttributesTableCrudService
    );
    const productPricesSummaryService = new ProductPricesSummaryService(dasService);
    const customerPricesSummaryService = new CustomerPricesSummaryService(dasService);
    const productPricesCommentsService = new ProductPricesCommentsService(dasService);
    const customerPricesCommentsService = new CustomerPricesCommentsService(dasService);
    const customerPriceApprovalService = new CustomerPriceApprovalService(exchangeRatesService);
    const nonStandardUomDropdownService = new DropDownService<ITableNonStandardUOMConversion>(
        dasService,
        VIEW_MATERIAL_NON_STANDARD_UOM_CONVERSION
    );
    const uomDropdownService = new DropDownService<ITableUOMConversion>(dasService, TABLE_UOM_CONVERSION);
    const uomConversionService = new UomConversionService(nonStandardUomDropdownService, uomDropdownService);
    const customerPricesExceptionsService = new CustomerPricesExceptionsService(
        dasService,
        customerPriceApprovalService,
        uomConversionService,
        exchangeRatesService
    );

    const customerPricesService = new CustomerPricesService(
        rlsDasService,
        customerPriceApprovalService,
        uomConversionService,
        exchangeRatesService
    );
    const customerPricesPersistedAttributesService = new CustomerPricesPersistedAttributesService(
        rlsDasService,
        customerPricesService,
        exchangeRatesService
    );
    const customerPricesQuoteService = new CustomerPricesQuoteService(exportService, dasService);

    const batchMetaDataService = new BatchMetaDataService(dasService);
    const batchService = new BatchService(batchMetaDataService, eventService);

    // const userBusinessLineDropdownService = new StoredProcDropDownService<
    //     ISpDropdownUserBusinessLine,
    //     ISpDropdownUserBusinessLineParams
    // >(dasService, 'spDropdownUserBusinessLines');

    const customerPricesSoldToDropdownService = new DropDownService<IVwDropDownCustomerPricesSoldTo>(
        dasService,
        'vwDropdownCustomerPricesSoldToCustomers'
    );

    const customerPricesShipToDropdownService = new DropDownService<IVwDropDownCustomerPricesShipTo>(
        dasService,
        'vwDropdownCustomerPricesShipToCustomers'
    );

    const customerPricesOrgRegionDropdownService = new DropDownService<IVwDropdownCustomerPricesOrgRegions>(
        dasService,
        'vwDropdownCustomerPricesOrgRegions'
    );

    const customerPricesMaterialDropdownService = new DropDownService<IVwDropdownCustomerPricesMaterials>(
        dasService,
        'vwDropdownCustomerPricesMaterials'
    );

    const customerPricesApplicationDropdownService = new DropDownService<IVwDropdownCustomerPricesApplications>(
        dasService,
        'vwDropdownCustomerPricesApplications'
    );

    const customerPricesMarketSegmentDropdownService = new DropDownService<IVwDropdownCustomerPricesMarketSegments>(
        dasService,
        'vwDropdownCustomerPricesMarketSegments'
    );

    const customerPricesAddExistingSalesOrganizationDropdownService = new DropDownService<IVwDropdownCustomerPricesAddExistingSalesOrganizations>(
        dasService,
        'vwDropdownCustomerPricesAddExistingSalesOrganizations'
    );

    const customerPricesAddProspectiveSalesOrganizationDropdownService = new DropDownService<IVwDropdownCustomerPricesAddProspectiveSalesOrganizations>(
        dasService,
        'vwDropdownCustomerPricesAddProspectiveSalesOrganizations'
    );

    const customerPricesAccountManagerDropdownService = new DropDownService<IVwDropdownCustomerPricesAccountManagers>(
        dasService,
        'vwDropdownCustomerPricesAccountManagers'
    );

    const productPricesMaterialDropdownService = new DropDownService<IVwDropdownProductPricesMaterials>(
        dasService,
        'vwDropdownProductPricesMaterials'
    );
    const productPricesOrgRegionDropdownService = new DropDownService<IVwDropdownProductPricesOrgRegions>(
        dasService,
        'vwDropdownProductPricesOrgRegions'
    );
    const productPricesApplicationDropdownService = new DropDownService<IVwDropdownProductPricesApplications>(
        dasService,
        'vwDropdownProductPricesApplications'
    );
    const productPricesMarketSegmentDropdownService = new DropDownService<IVwDropdownProductPricesMarketSegments>(
        dasService,
        'vwDropdownProductPricesMarketSegments'
    );
    const productPricesCompanyNameDropdownService = new DropDownService<IVwDropdownCompanyNames>(
        dasService,
        'vwCompanies'
    );
    const productPricesCompanyAddressDropdownService = new DropDownService<IVwDropdownCompanyAddresses>(
        dasService,
        'vwAddresses'
    );

    const productPricesTableCrudService = new TableCrudService<ITableProductPrices>(dasService, 'ProductPrices');
    const productPricesAddNewService = new ProductPricesAddNewService(
        dasService,
        productPricesTableCrudService,
        productPricesPersistedAttributesTableCrudService
    );

    const customerPricesTableCrudService = new TableCrudService<ITableCustomerPrices>(dasService, 'CustomerPrices');
    const customerPricesAddExistingSAPService = new CustomerPricesAddExistingSAPService(
        dasService,
        customerPricesTableCrudService,
        customerPricesMaterialDropdownService,
        uomConversionService
    );
    const customerPricesAddProspectiveService = new CustomerPricesAddProspectiveService(
        dasService,
        customerPricesTableCrudService,
        customerPricesMaterialDropdownService,
        uomConversionService
    );
    const productPricesMarketPriceHistoryService = new ProductPricesMarketPriceHistoryService(dasService);

    const quoteMostRecentApprovedService = new GridService<IViewQuoteMostRecentApproved>(
        dasService,
        'vwQuoteMostRecentApproved'
    );

    const uomNonStandardAndStandardDropdownService = new DropDownService<IViewDropdownNonStandardAndStandardUoms>(
        dasService,
        VIEW_STANDARD_NON_STANDARD_UOMS
    );

    const namedQueryService = new NamedQueryService(dasService, CLIENT_ID, DATABASE_LABEL);

    return {
        appConfig,
        eventService,
        productPricesService,
        productPricesSummaryService,
        customerPricesSummaryService,
        customerPriceApprovalService,
        productPricesCommentsService,
        productPriceApprovalService,
        productPricesLocksService,
        customerPricesService,
        customerPricesCommentsService,
        customerPricesExceptionsService,
        customerPricesOrgRegionDropdownService,
        customerPricesMaterialDropdownService,
        customerPricesApplicationDropdownService,
        exchangeRatesService,
        authService,
        powerBiService,
        batchService,
        batchMetaDataService,
        customerPricesPersistedAttributesService,
        customerPricesQuoteService,
        customerPricesSoldToDropdownService,
        customerPricesShipToDropdownService,
        customerPricesMarketSegmentDropdownService,
        customerPricesAddExistingSalesOrganizationDropdownService,
        customerPricesAddProspectiveSalesOrganizationDropdownService,
        customerPricesAccountManagerDropdownService,
        customerPricesAddExistingSAPService,
        customerPricesAddProspectiveService,
        // businessLineDropdownService,
        productPricesMaterialDropdownService,
        productPricesOrgRegionDropdownService,
        productPricesApplicationDropdownService,
        productPricesMarketSegmentDropdownService,
        productPricesAddNewService,
        productPricesMarketPriceHistoryService,
        authClientName,
        nextAuthority,
        uomDropdownService,
        uomConversionService,
        nonStandardUomDropdownService,
        productPricesPersistedAttributesService,
        quoteMostRecentApprovedService,
        uomNonStandardAndStandardDropdownService,
        productPricesCompanyNameDropdownService,
        productPricesCompanyAddressDropdownService,
        namedQueryService,
    };
}
