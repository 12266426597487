import { Box, Button } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { customerPriceStatuses } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useCustomerPricesExceptionsSubmitMutation } from 'shared/mutations';
import { IViewCustomerPricesExceptions } from 'shared/types';

export function CustomerPricesExceptionsSubmitAction({
    params,
}: {
    params: GridRowParams<IViewCustomerPricesExceptions>;
}) {
    const { onSubmit, isLoading } = useCustomerPricesExceptionsSubmitMutation();
    const permissions = useUserPermissions();

    if (params.row.status === 'Approved') return <Box>Submitted</Box>;

    const isSubmitAllowed =
        params.row.status === customerPriceStatuses.NEEDS_REVIEW ||
        params.row.status === customerPriceStatuses.NO_CHANGE ||
        params.row.status === customerPriceStatuses.APPROVED;

    return (
        <Button
            variant='contained'
            onClick={() => {
                onSubmit(params.row);
            }}
            disabled={isLoading || !isSubmitAllowed || !permissions.data.isCustomerExceptionsWriteAllowed}
        >
            Submit
        </Button>
    );
}
